/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


import * as bootstrap from 'bootstrap';
import * as highcharts from 'highcharts';
import accessibility from 'highcharts/modules/accessibility';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import ChartModuleMore from 'highcharts/highcharts-more.js';
accessibility(highcharts);
ChartModuleMore(highcharts);
HCSoldGauge(highcharts);
console.log(highcharts);

window.Highcharts = highcharts;


/*
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';

window.Highcharts = highcharts;

highchartsMore(Highcharts);


import {LineChart, Interpolation}  from 'chartist';

window.LineChart = LineChart;
window.Interpolation = Interpolation;
*/
/*new BarChart('#chart', {
    labels: ['W1', 'W2', 'W3', 'W4', 'W5', 'W6', 'W7', 'W8', 'W9', 'W10'],
    series: [
      [1, 2, 4, 8, 6, -2, -1, -4, -6, -2]
    ]
  }, {
    high: 10,
    low: -10,
    axisX: {
      labelInterpolationFnc: (value, index) => (index % 2 === 0 ? value : null)
    }
  });
*/
//window.BarChart = BarChart;
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
//     wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });
